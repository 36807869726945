import starIcon from "../../../assets/images/star-icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const ClientTestimony = () => {
  return (
    <div className="mt-12 md:mt-24 flex-col flex justify-center items-center container mx-auto">
      <h1 className="text-[26px] text-left md:text-center md:text-[44px]">
        What <span className="text-voilet font-b ">Our Clients </span> Are
        Saying
      </h1>
      <div className=" w-full">
        <Swiper
          slidesPerView={1}
          grabCursor={true}
          centeredSlides={true}
          modules={[Pagination]}
          // slidesPerView={"auto"}
          pagination={{
            el: ".my-custom-pagination-div",
            clickable: true,
            renderBullet: (index, className) => {
              return (
                '<span class="' +
                className +
                ' bg-darkVoilet p-1.5">' +
                "</span>"
              );
            },
          }}
          scrollbar={{ draggable: true }}
          spaceBetween={0}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 24,
            },

            1024: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
          }}
          initialSlide={1}
          className="mySwiper my-8 md:mb-8"
        >
          <SwiperSlide>
            <div className="px-4 lg:px-6 py-4 mx-auto ">
              <div className="lg:text-lg font-normal tracking-wide xl:leading-loose  ">
                <div className="shadow-lg p-8 rounded-lg bg-white">
                  <div className=" flex my-4 justify-between">
                    <div>
                      <div className="font-semibold font-b text-lg pr-2">
                        Zachary Feldman
                      </div>
                      <span className="text-gray-400">Volo Apps</span>
                    </div>
                    <div className="flex justify-center gap-2">
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                    </div>
                  </div>
                  <div className="flex text-gray-400 border-t pt-6">
                    "Aside from being friendly, prompt, and easy to work with,
                    their technical expertise was unmatched..." - Zachary
                    Feldman.
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="px-4 lg:px-6 py-4 mx-auto ">
              <div className="lg:text-lg font-normal tracking-wide xl:leading-loose  ">
                <div className="shadow-lg p-8 rounded-lg bg-white">
                  <div className=" flex my-4 justify-between">
                    <div>
                      <div className="font-semibold font-b text-lg pr-2">
                        Colton Cruse
                      </div>
                      <span className="text-gray-400">FareHarbor</span>
                    </div>
                    <div className="flex justify-center gap-2">
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                    </div>
                  </div>
                  <div className="flex text-gray-400 border-t pt-6">
                    "You will be hard pressed to hire better developers. Working
                    with Intldevs was great" - Colton Cruse.
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="px-4 lg:px-6 py-4 mx-auto ">
              <div className="lg:text-lg font-normal tracking-wide xl:leading-loose  ">
                <div className="shadow-lg p-8 rounded-lg bg-white">
                  <div className=" flex my-4 justify-between">
                    <div>
                      <div className="font-semibold font-b text-lg pr-2">
                        Bradley Delaune
                      </div>
                      <span className="text-gray-400">Venn</span>
                    </div>
                    <div className="flex justify-center gap-2">
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                    </div>
                  </div>
                  <div className="flex text-gray-400 border-t pt-6">
                    "Pablo and his team always do excellent work. After the
                    collaboration, our client hired half the team!" - Bradley
                    Delaune.
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="px-4 lg:px-6 py-4 mx-auto ">
              <div className="lg:text-lg font-normal tracking-wide xl:leading-loose  ">
                <div className="shadow-lg p-8 rounded-lg bg-white">
                  <div className=" flex my-4 justify-between">
                    <div>
                      <div className="font-semibold font-b text-lg pr-2">
                        Nick Tubis
                      </div>
                      <span className="text-gray-400">TSD Global</span>
                    </div>
                    <div className="flex justify-center gap-2">
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                      <img src={starIcon} className="w-4 h-4" alt="star" />
                    </div>
                  </div>
                  <div className="flex text-gray-400 border-t pt-6">
                    "Intldevs can do things that would take a normal team
                    months, several weeks" - Nick Tubis.
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="flex justify-center items-center gap-3">
        <div className="my-custom-pagination-div" />
      </div>
    </div>
  );
};

export { ClientTestimony };
