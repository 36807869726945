import styled from '@emotion/styled';

const Layout = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
`;

const Page = styled.div`
    @media not screen and (max-width: 940px) {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr repeat(12, 67px) 1fr;
        grid-template-areas: "header header header header header header header header header header header header header header"
        "Navigation Navigation Navigation Navigation Navigation Navigation Navigation Navigation Navigation Navigation Navigation Navigation Navigation Navigation"
        "Banner Banner Banner Banner Banner Banner Banner Banner Banner Banner Banner Banner Banner Banner"
        "leftMargin ourClients ourClients ourClients ourClients ourClients ourClients ourClients ourClients ourClients ourClients ourClients ourClients rightMargin"
        "leftMargin AboutUs AboutUs AboutUs AboutUs AboutUs AboutUs AboutUs AboutUs AboutUs AboutUs AboutUs AboutUs rightMargin"
        "OurPortfolio OurPortfolio OurPortfolio OurPortfolio OurPortfolio OurPortfolio OurPortfolio OurPortfolio OurPortfolio OurPortfolio OurPortfolio OurPortfolio OurPortfolio OurPortfolio"
        "OurServices OurServices OurServices OurServices OurServices OurServices OurServices OurServices OurServices OurServices OurServices OurServices OurServices OurServices"
        "ClientTestimony ClientTestimony ClientTestimony ClientTestimony ClientTestimony ClientTestimony ClientTestimony ClientTestimony ClientTestimony ClientTestimony ClientTestimony ClientTestimony ClientTestimony ClientTestimony"
        "Footer Footer Footer Footer Footer Footer Footer Footer Footer Footer Footer Footer Footer Footer";
        grid-gap: 0 30px;
    }

    @media screen and (max-width: 940px) {
        width: 100%;
    }
    
`;

export {
    Layout,
    Page
};