export default function ContactPage() {
  return (
    <div>
      <div className="flex justify-center ">
        <h1 className="text-voilet text-[20px] space-letter mt-8 lg:text-[30px]">
          HOME/CONTACT US
        </h1>
      </div>
      <div className="container mx-auto px-4 mt-12 grid 2xl:grid-cols-2 2xl:px-44 ">
        <form className="2xl:px-12">
          <h1 className="text-[26px] mt-20 lg:text-[44px]">
            Get in <span className="text-voilet">Touch</span>
          </h1>
          <div className="mt-4 space-y-4">
            <input
              className="p-4  w-full bg-background rounded-lg"
              placeholder="Your full name"
              required
            />
            <input
              className="p-4   w-full bg-background rounded-lg "
              placeholder="Your email address"
              required
              type="email"
            />
            <textarea
              className="p-4 lg:p-6 lg:h-[185px] w-full bg-background rounded-lg"
              placeholder="Write message here"
              required
            />
          </div>
          <button
            type="submit"
            className="cursor-pointer w-full h-[50px] lg:h-[58px] bg-darkBlue rounded-[10px] flex justify-center items-center mt-4 lg:mt-12 lg:w-[200px]"
          >
            <span className=" text-[18px] lg:text-[16px] text-white weight font-b">
              SEND MESSAGE
            </span>
          </button>
        </form>
        <div>
          <div className="mt-12 font-bold  2xl:w-[55%]   lg:mt-36">
            <p className="text-[26px] my-cl lg:text-[30px]">
              You've Any <span className="text-voilet font-b ">Question?</span>
              Feel Free To Contact{" "}
              <span className="text-voilet font-b">With Us.</span>
            </p>
          </div>
          <div className="mt-4 lg:mt-6">
            <p className="text-textblue text-[22px]">
              Call us for imidiate support on this number
            </p>
            <h1 className="text-voilet text-[20px] lg:text-[22px] font-t mt-4">
              + (801)-477-5186
            </h1>
          </div>
          <div className="mt-2 lg:mt-10 ">
            <p className="text-textblue text-[22px] lg:text-[24px]">
              Send us email for any kind of inquiry
            </p>
            <h1 className="text-voilet text-[20px] lg:text-[22px] mt-2">
              contactus@intl.dev
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
