import styled from "@emotion/styled";
import phoneCallIcon from "../../../assets/images/phone-call-icon.svg";
import heroAboutUs from "../../../assets/branding/hero-aboutus.svg";

const AboutUsContainer = styled.div`
  @media not screen and (max-width: 940px) {
    grid-area: AboutUs;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: 940px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    //margin-top: 50px;
  }
`;

const AboutUsImage = styled.img`
  @media not screen and (max-width: 940px) {
    width: 558px;
    height: 317px;
    margin-right: 51px;
  }

  @media screen and (max-width: 940px) {
    width: 328px;
    height: 317px;
    margin-right: 51px;
    margin-left: 17px;
  }
`;

const AboutUsBlock = styled.div`
  @media not screen and (max-width: 940px) {
    grid-area: AboutUs;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  @media screen and (max-width: 940px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 328px;
    margin-left: 17px;
  }
`;

const AboutUsText = styled.div`
  @media not screen and (max-width: 940px) {
    font-family: "Nolan Next";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2em;
    color: #857e98;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 940px) {
    font-family: "Nolan Next";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2em;
    color: #857e98;
    margin-left: 17px;
    margin-top: 50px;
  }
`;

const AboutUsTitle = styled.div`
  @media not screen and (max-width: 940px) {
    font-family: ${(props) =>
      props.fontStyle === "bold"
        ? "Nolan Next ExtraBold"
        : "Nolan Next Medium"};
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 55px;
    color: ${(props) => (props.color ? props.color : `#000000`)};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : `0px`)};
    white-space: nowrap;
  }

  @media screen and (max-width: 940px) {
    font-family: ${(props) =>
      props.fontStyle === "bold"
        ? "Nolan Next ExtraBold"
        : "Nolan Next Medium"};
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    color: ${(props) => (props.color ? props.color : `#000000`)};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : `0px`)};
    white-space: nowrap;
    margin-top: 8px;
  }
`;

const AboutUsSubTitile = styled.div`
  @media not screen and (max-width: 940px) {
    font-family: "Nolan Next Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 55px;
    color: #000000;
    white-space: nowrap;
    margin-bottom: 19px;
  }

  @media screen and (max-width: 940px) {
    font-family: "Nolan Next Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    color: #000000;
    margin-left: 17px;
    white-space: nowrap;
  }
`;
const AboutUsPara = styled.div`
  @media not screen and (max-width: 940px) {
    font-family: "Nolan Next";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    color: #857e98;
    list-style: none;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 940px) {
    font-family: "Nolan Next";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #857e98;
    list-style: none;
    margin-bottom: 30px;
  }
`;
const AboutUsCallLogo = styled.div`
  width: 57px;
  height: 53px;
  background: linear-gradient(
    99.56deg,
    #2e308b 3.52%,
    #63307a 41.78%,
    #3f2179 94.29%
  );
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AboutUsCallLogoImage = styled.img`
  width: 30px;
  height: 30px;
`;

const AboutUsCallTitle = styled.div`
  width: 169px;
  height: 35px;
  font-family: "Nolan Next";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  color: #857e98;
`;

const AboutUsPhoneNumber = styled.div`
  width: 152px;
  height: 28px;
  font-family: ${(props) =>
    props.fontStyle === "bold" ? "Nolan Next ExtraBold" : "Nolan Next Medium"};
  font-style: bold;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #7456cc;
  white-space: nowrap;
`;

const AboutUsWithParaBulletPointsContainer = styled.div`
  font-family: "Nolan Next";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  color: #857e98;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 11px;
`;

const BulletHorizontalBlock = styled.div`
  font-family: "Nolan Next";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  color: #857e98;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const HorizontalBlock = styled.div`
  @media not screen and (max-width: 940px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 940px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2px;
    margin-left: 16px;
  }
`;

const VerticalBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 11px;
`;

const Bullet = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background: #7456cc;
  content: ".";
  margin-right: 11px;
`;
const ToggleTitleForMobile = styled.div`
  @media not screen and (max-width: 940px) {
    ${(props) => props.isMobile && "display:none;"}
  }

  @media screen and (max-width: 940px) {
    ${(props) => !props.isMobile && "display:none;"}
  }
`;

const MobileImageWrapper = styled.div`
  @media screen and (max-width: 940px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    //margin-top: 30px;
  }
`;

const AboutUs = ({ src }) => {
  return (
    <div className="mx-auto container">
      <AboutUsContainer>
        <ToggleTitleForMobile isMobile>
          <AboutUsText>ABOUT US</AboutUsText>
          <HorizontalBlock>
            <AboutUsTitle>Modern IT &</AboutUsTitle>
            <AboutUsTitle marginLeft="10px" color="#7456CC" fontStyle="bold">
              Tech Services &
            </AboutUsTitle>
          </HorizontalBlock>
          <AboutUsSubTitile>Digital Experience</AboutUsSubTitile>
        </ToggleTitleForMobile>
        <MobileImageWrapper>
          <AboutUsImage src={heroAboutUs} />
        </MobileImageWrapper>
        <AboutUsBlock>
          <ToggleTitleForMobile>
            <AboutUsText>ABOUT US</AboutUsText>
            <HorizontalBlock>
              <AboutUsTitle>Modern IT &</AboutUsTitle>
              <AboutUsTitle marginLeft="25px" color="#7456CC" fontStyle="bold">
                Tech Services &
              </AboutUsTitle>
            </HorizontalBlock>
            <AboutUsSubTitile>Digital Experience</AboutUsSubTitile>
          </ToggleTitleForMobile>
          <AboutUsPara>
            <div>
             Our team specializes in creating dependable and scalable software for all operating 
            </div>
            <div>
            systems, browsers, and devices. Combining industry knowledge and the newest technology, 
            </div>
            <div>we deliver custom-made solutions and products that align with user needs and preferences.</div>
          </AboutUsPara>
          <HorizontalBlock>
            <AboutUsCallLogo>
              <AboutUsCallLogoImage src={phoneCallIcon} />
            </AboutUsCallLogo>
            <VerticalBlock>
              <AboutUsCallTitle>Call to ask questions:</AboutUsCallTitle>
              <AboutUsPhoneNumber fontStyle="bold">
                +1 801-477-5186
              </AboutUsPhoneNumber>
            </VerticalBlock>
          </HorizontalBlock>
          <AboutUsWithParaBulletPointsContainer>
            <BulletHorizontalBlock>
              <Bullet />
              Web Development
            </BulletHorizontalBlock>
            <BulletHorizontalBlock>
              <Bullet />
              Mobile Application
            </BulletHorizontalBlock>
            <BulletHorizontalBlock>
              <Bullet />
              Domain & Hosting Service
            </BulletHorizontalBlock>
            <BulletHorizontalBlock>
              <Bullet />
              Graphics Design
            </BulletHorizontalBlock>
          </AboutUsWithParaBulletPointsContainer>
        </AboutUsBlock>
      </AboutUsContainer>
    </div>
  );
};

export { AboutUs };
