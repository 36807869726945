import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import footerWave from "../../../assets/images/footer-wave-image.svg";
import footerLogo from "../../../assets/images/footer-logo-image.svg";
import GoogleIcon from "../../../assets/images/google-icon-grey.svg";
import FacebookIcon from "../../../assets/images/facebook-icon-grey.svg";
import TweeterIcon from "../../../assets/images/tweeter-icon-grey.svg";
import InstagramIcon from "../../../assets/images/instagram-icon-grey.svg";

const Icon = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "0px;")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px;")};
`;

const LogoContainer = () => {
  return (
    <>
      <Icon height="46.25px" width="170px" src={footerLogo} />
    </>
  );
};

const breakPointWidth = 1200;

const BannerContainer = styled.div`
  @media not screen and (max-width: ${breakPointWidth}px) {
    display: grid;
    grid-template-columns: 1fr repeat(12, 67px) 1fr;
    grid-template-areas: "serviceContainer serviceContainer serviceContainer serviceContainer serviceContainer serviceContainer serviceContainer serviceContainer serviceContainer serviceContainer serviceContainer serviceContainer serviceContainer serviceContainer";
    grid-gap: 0 30px;
    grid-template-rows: 723px;
    grid-area: Footer;
    margin-top: 0px;
  }
`;

const BannerBackground = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  width: 100%;
  height: 100%;
  grid-area: serviceContainer;
`;

const Container = styled.div`
  @media not screen and (max-width: ${breakPointWidth}px) {
    display: grid;
    grid-template-columns: 1fr repeat(12, 67px) 1fr;
    grid-template-areas: "leftMargin Container Container Container Container Container Container Container Container Container Container Container Container rightMargin";
    grid-gap: 0 30px;
    grid-template-rows: 723px;
  }
`;
const OurServiceBlock = styled.div`
  @media not screen and (max-width: ${breakPointWidth}px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    order: ${(props) => props.desktopOrder && props.desktopOrder};
    //margin-top: 125px;
    //margin-right: 73px;
    //height:100%;
  }

  @media screen and (max-width: ${breakPointWidth}px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    order: ${(props) => props.mobileOrder && props.mobileOrder};
  }
`;

const OurServicePara = styled.div`
  @media not screen and (max-width: ${breakPointWidth}px) {
    font-family: "Nolan Next";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    color: #857e98;
    margin-bottom: 30px;
    margin-top: 40px;
  }

  @media screen and (max-width: ${breakPointWidth}px) {
    font-family: "Nolan Next";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    color: #857e98;
    margin-bottom: 35px;
    margin-top: 25px;
    max-width: 370px;
  }
`;

const HorizontalBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  //margin-bottom: 20px;
  width: ${(props) => (props.width ? props.width : "270px")};
  flex-wrap: wrap;
`;

const InnerContainer = styled.div`
  @media not screen and (max-width: ${breakPointWidth}px) {
    grid-area: Container;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: ${breakPointWidth}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 145px;
    margin-left: 17px;
  }
`;

const Hr = styled.div`
  @media not screen and (max-width: ${breakPointWidth}px) {
    border-top: 1px solid #d8d8d8;
    content: "";
    width: 100%;
    margin-top: 64px;
    margin-bottom: 11px;
    order: ${(props) => props.desktopOrder && props.desktopOrder};
  }

  @media screen and (max-width: ${breakPointWidth}px) {
    border-top: 1px solid #d8d8d8;
    content: "";
    width: 100%;
    margin-top: 64px;
    margin-bottom: 11px;
    order: ${(props) => props.mobileOrder && props.mobileOrder};
  }
`;

const CopyRightContainer = styled.div`
  @media not screen and (max-width: ${breakPointWidth}px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    order: ${(props) => props.desktopOrder && props.desktopOrder};
  }

  @media screen and (max-width: ${breakPointWidth}px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    order: ${(props) => props.mobileOrder && props.mobileOrder};
  }
`;

const CopyRight = styled.div`
  width: 373px;
  height: 21px;
  font-family: "Nolan Next";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  color: #857e98;
`;

const QuickLinks = styled.div`
  @media not screen and (max-width: ${breakPointWidth}px) {
    width: 112px;
    height: 24px;
    font-family: ${(props) =>
      props.fontStyle === "bold" ? "Nolan Next Bold" : "Nolan Next Medium"};
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-top: 48px;
  }

  @media screen and (max-width: ${breakPointWidth}px) {
    width: 112px;
    height: 24px;
    font-family: ${(props) =>
      props.fontStyle === "bold" ? "Nolan Next Bold" : "Nolan Next Medium"};
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-top: 0px;
  }
`;

const InputWrapper = styled.div`
  @media not screen and (max-width: ${breakPointWidth}px) {
    width: 400px;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  @media screen and (max-width: ${breakPointWidth}px) {
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 48px;
    width: 350px;
  }
`;

const Input = styled.input`
  padding: 14px 0px 14px 20px;
  border: 1px solid #d6d6d6;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 303px;
  height: 49px;
  font-family: "Nolan Next";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
`;

const Button = styled.button`
  padding: 14px 28px;
  background-color: #60307b;
  color: #ffffff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: "Nolan Next";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
`;

const SocialIconsContainer = styled.div`
  @media not screen and (max-width: ${breakPointWidth}px) {
    grid-area: social;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    margin-top: 10px;
  }

  @media screen and (max-width: ${breakPointWidth}px) {
    ${(props) => (props.mobile ? "" : "display:none")};
  }
`;

const SocialIconsContainerHorizontal = styled.div`
  @media not screen and (max-width: ${breakPointWidth}px) {
    display: none;
  }

  @media screen and (max-width: ${breakPointWidth}px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    ${(props) => (props.mobile ? "order: 4" : "display:none")};
  }
`;
const SocialIcons = styled.div`
  padding: 10px 0px;
  ${(props) => !props.skipMargin && "margin: 0px 14px"};
  color: ${(props) => (props.color ? props.color : "#FFFFFF")};
`;

const FooterBox = () => {
  return (
    <BannerContainer>
      <BannerBackground src={footerWave}>
        <Container>
          <InnerContainer>
            <HorizontalBlock width="100%">
              <OurServiceBlock desktopOrder="1" mobileOrder="1">
                <LogoContainer />
                <OurServicePara>
                  <div>As a leading global Information</div>
                  <div>Technology Company, we are committed</div>
                  <div> to delivering the highest quality hardware,</div>
                  <div>software, and network solutions.</div>
                </OurServicePara>
              </OurServiceBlock>
              <OurServiceBlock desktopOrder="2" mobileOrder="3">
                <QuickLinks fontStyle="bold">Quick Links</QuickLinks>
                <OurServicePara>
                  <Link to="/">
                    <div>Home</div>
                  </Link>
                  <div>About Us</div>
                  <Link to="/contact">
                    <div>Contact Us</div>
                  </Link>
                  <Link to="/portfolio">
                    <div>Portfolio</div>
                  </Link>
                </OurServicePara>
              </OurServiceBlock>
              <OurServiceBlock desktopOrder="3" mobileOrder="2">
                <QuickLinks fontStyle="bold">Contact US</QuickLinks>
                <OurServicePara>
                  <div>
                    We have the technology and industry expertise to develop
                  </div>
                  <div>solutions that can connect people and businesses</div>
                </OurServicePara>
                <HorizontalBlock width="100%">
                  <InputWrapper>
                    <Input placeholder="Enter Email" />
                    <Button>Send</Button>
                  </InputWrapper>
                </HorizontalBlock>
                <SocialIconsContainer>
                  <SocialIcons>
                    <Icon
                      className="color"
                      height="19px"
                      width="16px"
                      marginRight="10px"
                      src={FacebookIcon}
                    />
                  </SocialIcons>
                  <SocialIcons>
                    <Icon
                      height="19px"
                      width="16px"
                      marginRight="10px"
                      src={TweeterIcon}
                    />
                  </SocialIcons>
                  <SocialIcons>
                    <Icon
                      height="19px"
                      width="16px"
                      marginRight="10px"
                      src={InstagramIcon}
                    />
                  </SocialIcons>
                  <SocialIcons>
                    <Icon
                      height="19px"
                      width="16px"
                      marginRight="10px"
                      src={GoogleIcon}
                    />
                  </SocialIcons>
                </SocialIconsContainer>
              </OurServiceBlock>
              <SocialIconsContainerHorizontal mobile>
                <SocialIcons>
                  <Icon
                    skipMargin
                    className="color"
                    height="19px"
                    width="16px"
                    marginRight="10px"
                    src={FacebookIcon}
                  />
                </SocialIcons>
                <SocialIcons>
                  <Icon
                    height="19px"
                    width="16px"
                    marginRight="10px"
                    src={TweeterIcon}
                  />
                </SocialIcons>
                <SocialIcons>
                  <Icon
                    height="19px"
                    width="16px"
                    marginRight="10px"
                    src={InstagramIcon}
                  />
                </SocialIcons>
                <SocialIcons>
                  <Icon
                    height="19px"
                    width="16px"
                    marginRight="10px"
                    src={GoogleIcon}
                  />
                </SocialIcons>
              </SocialIconsContainerHorizontal>
              <Hr desktopOrder="4" mobileOrder="5">
                &nbsp;
              </Hr>
              <CopyRightContainer desktopOrder="5" mobileOrder="6">
                <CopyRight>
                  Copyright © 2022 intl.dev. All Rights Reserved.
                </CopyRight>
              </CopyRightContainer>
            </HorizontalBlock>
          </InnerContainer>
        </Container>
      </BannerBackground>
    </BannerContainer>
  );
};

export { FooterBox };
