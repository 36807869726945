import styled from "@emotion/styled";
import cloudIntegration from "../../../assets/branding/cloud-integration.svg";
import itConsultancy from "../../../assets/branding/it-consultancy.svg";
import itDesignProject from "../../../assets/branding/it-design-project.svg";
import productEngineer from "../../../assets/branding/product-engineer.svg";

const OurServiceText = styled.div`
  @media not screen and (max-width: 940px) {
    width: 172px;
    height: 17.93px;
    font-family: "Nolan Next";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0.2em;
    color: #9f80ef;
    margin-bottom: 20px;
    white-space: nowrap;
  }

  @media screen and (max-width: 940px) {
    width: 172px;
    height: 17.93px;
    font-family: "Nolan Next";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0.2em;
    color: #9f80ef;
    margin-bottom: 20px;
    white-space: nowrap;
  }
`;

const OurServiceTitle = styled.div`
  @media not screen and (max-width: 940px) {
    font-family: ${(props) =>
      props.fontStyle === "bold"
        ? "Nolan Next ExtraBold"
        : "Nolan Next Medium"};
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 55px;
    color: ${(props) => (props.color ? props.color : `#FFFFFF`)};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : `0px`)};
    white-space: nowrap;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 940px) {
    font-family: ${(props) =>
      props.fontStyle === "bold"
        ? "Nolan Next ExtraBold"
        : "Nolan Next Medium"};
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: ${(props) => (props.color ? props.color : `#FFFFFF`)};
    margin-bottom: 20px;
    width: 326px;
  }
`;

const OurServiceSubTitile = styled.div`
  @media not screen and (max-width: 940px) {
    font-family: ${(props) =>
      props.fontStyle === "bold"
        ? "Nolan Next ExtraBold"
        : "Nolan Next Medium"};
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 55px;
    color: ${(props) => (props.color ? props.color : `#FFFFFF`)};
    white-space: nowrap;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 940px) {
    font-family: "Nolan Next Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: ${(props) => (props.color ? props.color : `#FFFFFF`)};
    white-space: nowrap;
    margin-bottom: 40px;
    width: 326px;
  }
`;

const OurServicePara = styled.div`
  @media not screen and (max-width: 940px) {
    font-family: "Nolan Next";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    color: #ffffff;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 940px) {
    font-family: "Nolan Next";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    color: #ffffff;
    margin-bottom: 30px;
    width: 326px;
  }
`;


const PortfolioName = styled.div`
  @media not screen and (max-width: 940px) {
    font-family: "Nolan Next Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    white-space: nowrap;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  @media screen and (max-width: 940px) {
    font-family: "Nolan Next Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 11px;
    text-align: center;
    color: #000000;
    white-space: nowrap;
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

const OurServices = () => {
  return (
    <div>
      <div className="our-services mt-12">
        <div className="container mx-auto py-24 px-4 md:px-0">
          <div className="grid  md:grid-cols-2 gap-10">
            <div>
              <OurServiceText>OUR SERVICE</OurServiceText>
              <OurServiceTitle>We Offer a Wide Variety Of</OurServiceTitle>
              <OurServiceSubTitile color="#ae8fdc" fontStyle="bold">
                IT Services
              </OurServiceSubTitile>
              <OurServicePara>
                <div>
                  Web design is more than just a job for us, it's a passion.
                </div>
                <div> 
                  Our team believes in creating visually stunning designs
                </div>
                <div>that reflect the essence of your business.</div>
              </OurServicePara>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-white flex justify-center items-center flex-col rounded-lg py-6 md:py-0">
                <img src={itDesignProject} alt="designproject" className="h-8	w-8 md:h-16 md:w-16"/>
                <PortfolioName>Corporate Website</PortfolioName>
              </div>
              <div className="bg-white flex justify-center items-center flex-col rounded-lg py-6 md:py-0">
                <img src={productEngineer} alt="productengineer" className="h-8	w-8 md:h-16 md:w-16"/>
                <PortfolioName>Crypto App Project</PortfolioName>
              </div>
              <div className="bg-white flex justify-center items-center flex-col rounded-lg py-6 md:py-0">
                <img src={cloudIntegration} alt="designproject" className="h-8	w-8 md:h-16 md:w-16"/>
                <PortfolioName>Cloud Integration</PortfolioName>
              </div>
              <div className="bg-white flex justify-center items-center flex-col rounded-lg py-6 md:py-0">
                <img src={itConsultancy} alt="productengineer" className="h-8	w-8 md:h-16 md:w-16"/>
                <PortfolioName>IT Consultancy</PortfolioName>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { OurServices };
