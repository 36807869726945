import styled from "@emotion/styled";
import LocationIcon from "../../../assets/images/location-icon.svg";
import EmailIcon from "../../../assets/images/email-icon.svg";
import GoogleIcon from "../../../assets/images/google-icon.svg";
import FacebookIcon from "../../../assets/images/facebook-icon.svg";
import TweeterIcon from "../../../assets/images/tweeter-icon.svg";
import InstagramIcon from "../../../assets/images/instagram-icon.svg";
import RightArrowIcon from "../../../assets/images/right-arrow-icon.svg";
import LogoImage from "../../../assets/images/logo-image.svg";
import bannerCapsule from "../../../assets/branding/banner-capsule.svg";
import mobileBannerCapsule from "../../../assets/images/mobile-capsule-image.svg";
import hamburgerMenu from "../../../assets/images/hamburger-menu.svg";
import { Link, useLocation } from "react-router-dom";

const breakPoint = 1200;
const Title = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-area: inBanner;
    height: 100%;
    ::before {
      background-image: url(${bannerCapsule});
      background-size: cover;
      content: "";
      width: 230px;
      height: 213px;
      position: relative;
      left: 90px;
      top: 107px;
    }
    ::after {
      background-image: url(${bannerCapsule});
      background-size: cover;
      content: "";
      width: 230px;
      height: 213px;
      transform: rotate(180deg);
      position: relative;
      left: -475px;
      top: 146px;
    }
  }
  @media screen and (max-width: ${breakPoint}px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    height: 300px;
    margin-bottom: 150px;
    padding-left: 17px;

    ::before {
      background-image: url(${mobileBannerCapsule});
      background-size: cover;
      content: "";
      width: 80px;
      height: 80px;
      position: relative;
      left: 9px;
      top: 0px;
    }
  }
`;

const Market = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    color: #9f80ef;
    margin-bottom: 20px;
    font-family: "Nolan Next ExtraBold";
    font-style: normal;
    font-weight: 500;
    font-size: 55px;
    line-height: 60px;
    white-space: nowrap;
  }

  @media screen and (max-width: ${breakPoint}px) {
    color: #9f80ef;
    margin-bottom: 20px;
    font-family: "Nolan Next ExtraBold";
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 46px;
    white-space: nowrap;
  }
`;

const YourBusiness = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    color: #000000;
    margin-bottom: 20px;
    font-family: "Nolan Next ExtraBold";
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 50px;
    white-space: nowrap;
  }

  @media screen and (max-width: ${breakPoint}px) {
    color: #000000;
    margin-bottom: 20px;
    font-family: "Nolan Next ExtraBold";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    white-space: nowrap;
  }
`;

const MarketBody = styled.div`
  font-family: "Nolan Next";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #857e98;
  padding-right: 15px;
  white-space: break-spaces;
  margin-bottom: 20px;
`;

const MobileQuoteButton = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  @media screen and (max-width: ${breakPoint}px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;
const TitleBox = ({ title, subHeader, body }) => {
  return (
    <Title>
      <Market>{title}</Market>
      <YourBusiness>{subHeader}</YourBusiness>
      <MarketBody>{body}</MarketBody>
      <MobileQuoteButton>
        <Link to="/contact">
          <QuoteButtonBanner height="58px" width="200px">
            GET A QUOTE
            <Icon marginLeft="15px" src={RightArrowIcon} />
          </QuoteButtonBanner>
        </Link>
      </MobileQuoteButton>
    </Title>
  );
};

const HeaderBoxContainer = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    background-color: #000000;
    height: 50px;
    grid-area: header;

    display: grid;
    grid-template-columns: 1fr repeat(12) 1fr;
    grid-template-areas: "leftMargin Location Location Location Location Location Location Location header social social social social rightMargin";
    grid-gap: 0px 30px;
    width: 100%;
  }

  @media screen and (max-width: ${breakPoint}px) {
    background-color: #000000;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
`;

const HeaderBoxContent = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    grid-area: Location;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 50px;
  }

  @media screen and (max-width: ${breakPoint}px) {
    width: 100%;
  }
`;

const HeaderBox = ({ title, subHeader }) => {
  return (
    <HeaderBoxContainer>
      <HeaderBoxContent>
        <HeaderContacts />
      </HeaderBoxContent>
      <SocialIconsContainer>
        <SocialIcons>
          <Icon
            height="19px"
            width="16px"
            marginRight="10px"
            src={FacebookIcon}
          />
        </SocialIcons>
        <SocialIcons>
          <Icon
            height="19px"
            width="16px"
            marginRight="10px"
            src={TweeterIcon}
          />
        </SocialIcons>
        <SocialIcons>
          <Icon
            height="19px"
            width="16px"
            marginRight="10px"
            src={InstagramIcon}
          />
        </SocialIcons>
        <SocialIcons>
          <Icon
            height="19px"
            width="16px"
            marginRight="10px"
            src={GoogleIcon}
          />
        </SocialIcons>
      </SocialIconsContainer>
      {/* <YourBusiness>{subHeader}</YourBusiness> */}
    </HeaderBoxContainer>
  );
};
const Icon = styled.img`
  @media not screen and (max-width: ${breakPoint}px) {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    margin-right: ${(props) =>
      props.marginRight ? props.marginRight : "0px;"};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px;")};
  }

  @media screen and (max-width: ${breakPoint}px) {
    width: ${(props) =>
      props.width && props.locationIcon ? "25px" : props.width};
    height: ${(props) =>
      props.height && props.locationIcon ? "22px" : props.height};
    ${(props) => props.locationIcon && "margin-left:16px;"}
    ${(props) => props.emailIcon && "margin-left:27px;"}
  }
`;

const HeaderContacts = () => {
  return (
    <HeaderContact>
      <LocationNEmailDetailsIcon>
        <Icon
          locationIcon
          marginRight="15px"
          height="19px"
          width="19px"
          src={LocationIcon}
        />
      </LocationNEmailDetailsIcon>
      <LocationNEmailDetails>
        1022 South 580 West Payson, UT 84651
      </LocationNEmailDetails>
      <LocationNEmailDetails separator>|</LocationNEmailDetails>
      <LocationNEmailDetailsIcon>
        <Icon
          emailIcon
          marginRight="15px"
          height="19.33px"
          width="28px"
          src={EmailIcon}
        />
      </LocationNEmailDetailsIcon>
      <LocationNEmailDetails>contactus@intl.dev</LocationNEmailDetails>
    </HeaderContact>
  );
};

const LocationNEmailDetails = styled.div`
  font-family: "Nolan Next";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
  ${(props) => props.separator && "margin: 0px 10px;"}

  @media screen and (max-width: ${breakPoint}px) {
    display: none;
  }
`;

const LocationNEmailDetailsIcon = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    font-family: "Nolan Next";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #ffffff;
    ${(props) => props.separator && "margin: 0px 10px;"}
  }

  @media screen and (max-width: ${breakPoint}px) {
    font-family: "Nolan Next";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #ffffff;
    height: 25px;
    width: 25px;
    ${(props) => props.separator && "margin: 0px 10px;"}
  }
`;

const HeaderContact = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    grid-area: Location;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: ${breakPoint}px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;

const SocialIconsContainer = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    grid-area: social;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
  }

  @media screen and (max-width: ${breakPoint}px) {
    display: none;
  }
`;

const SocialIcons = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    padding: 14px 0px;
    margin: 7px 14px;
    color: #ffffff;
  }
`;

const NavigationHeaderContent = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    background-color: #60307b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;

    display: flex;
    grid-template-columns: 1fr repeat(12, 67px) 1fr;
    grid-template-areas: "leftMargin Location Location Location Location Location Location Location Location Location Location Location Location rightMargin";
    grid-gap: 0 30px;
  }

  @media screen and (max-width: ${breakPoint}px) {
    background-color: #60307b;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
  }
`;

const NavigationHeaderContainer = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    background-color: #60307b;
    grid-area: Navigation;
    width: 100%;
  }

  @media screen and (max-width: ${breakPoint}px) {
    background-color: #60307b;
    width: 100%;
  }
`;

const NavigationLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 428px;
  height: 18px;
  margin-top: 14px;

  @media screen and (max-width: ${breakPoint}px) {
    display: none;
  }
`;

const NavigationalInnerContainer = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    grid-area: Location;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  @media screen and (max-width: ${breakPoint}px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;

const MobileMenu = styled.img`
  @media not screen and (max-width: ${breakPoint}px) {
    width: 24px;
    height: 16px;
    display: none;
  }

  @media screen and (max-width: ${breakPoint}px) {
    width: 24px;
    height: 16px;
    margin: 21px 17px;
  }
`;

const MobileMenuWrapper = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    display: none;
  }

  @media screen and (max-width: ${breakPoint}px) {
  }
`;
const NavItem = styled.div`
  font-family: "Nolan Next";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 18px;
  color: #ffffff;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "30px;")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px;")};
  white-space: nowrap;
`;

const NavigationHeader = () => {
  const location = useLocation();

  return (
    <NavigationHeaderContainer>
      <div className="container mx-auto">
        <NavigationHeaderContent>
          <NavigationalInnerContainer>
            <Link to="/">
              <LogoContainer />
            </Link>
            <NavigationLinks>
              <Link to="/">
                <NavItem className={location.pathname === "/" ? "font-b" : ""}>
                  Home
                </NavItem>
              </Link>
              <Link to="/portfolio">
                <NavItem
                  className={location.pathname === "/portfolio" ? "font-b" : ""}
                >
                  Portfolio
                </NavItem>
              </Link>
              <Link to="/contact">
                <NavItem
                  className={location.pathname === "/contact" ? "font-b" : ""}
                >
                  Contact Us{" "}
                </NavItem>
              </Link>
            </NavigationLinks>
            <Link to="/contact">
              <QuoteButton height="40px" width="160px" primary>
                GET A QUOTE
              </QuoteButton>
            </Link>
            <MobileMenuWrapper>
              <MobileMenu src={hamburgerMenu} />
            </MobileMenuWrapper>
          </NavigationalInnerContainer>
        </NavigationHeaderContent>
      </div>
    </NavigationHeaderContainer>
  );
};

const LogoImageContainer = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    grid-area: Location;
    height: 46px;
  }

  @media screen and (max-width: ${breakPoint}px) {
    height: 46px;
    margin-left: 16px;
  }
`;

const QuoteButton = styled.button`
    font-family: 'Nolan Next ExtraBold';
    color: ${(props) => (props.primary ? "#60307B;" : "#FFFFFF;")}
    background-color: ${(props) => (!props.primary ? "#60307B;" : "#FFFFFF;")}
    width: ${(props) => (props.width ? props.width : "600px;")};
    height: ${(props) => (props.height ? props.height : "40px;")};
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    padding: 14px 18px;
    height: 40px;

    @media screen and (max-width: ${breakPoint}px) {
        display: none;
    }
`;

const QuoteButtonBanner = styled.button`
    @media not screen and (max-width: ${breakPoint}px) {
        font-family: 'Nolan Next ExtraBold';
        color: ${(props) => (props.primary ? "#60307B;" : "#FFFFFF;")}
        background-color: ${(props) =>
          !props.primary ? "#60307B;" : "#FFFFFF;"}
        width: ${(props) => (props.width ? props.width : "600px;")};
        height: ${(props) => (props.height ? props.height : "40px;")};
        border-radius: 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        padding: 22px 12px 22px 34px;
        display:flex;
        justify-content:center;
    }
    @media screen and (max-width: ${breakPoint}px) {
        font-family: 'Nolan Next ExtraBold';
        color: ${(props) => (props.primary ? "#60307B;" : "#FFFFFF;")}
        background: #60307B;
        width: 343px;
        height: 50px;
        border-radius: 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        padding: 19px 19px;
        display:flex;
        gap:10px;
        justify-content:center
    }
`;
const LogoContainer = () => {
  return (
    <LogoImageContainer>
      <Icon height="46.25px" width="170px" src={LogoImage} />
    </LogoImageContainer>
  );
};

export { Title, TitleBox, NavigationHeader, HeaderBox, LogoContainer };
