import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FooterBox } from "./features/Components/Footer";
import { HeaderBox, NavigationHeader } from "./features/Components/Header";
import { Layout } from "./features/Components/Layout";
import Contact from "./features/Contact";
import Home from "./features/Home";
import Portfolio from "./features/Portfolio";

function App() {
  return (
    <>
      <BrowserRouter>
        <Layout>
          <HeaderBox />
          <NavigationHeader />
          <Routes>
            <Route path="/contact" element={<Contact />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/" element={<Home />} />
          </Routes>
          <FooterBox />
        </Layout> 
      </BrowserRouter>
    </>
  );
}

export default App;
