import corpWebsite from "../../../assets/images/corp-website.svg";
import desktopIdea from "../../../assets/images/desktop-idea.svg";
import siftBg from "../../../assets/images/sift_with_bg.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/autoplay";

import WeTravel from "../../../assets/images/weTravel.png";
import Pals from "../../../assets/images/pals-center.png";
import FestPop from "../../../assets/images/FestPop.png";
import Predicting from "../../../assets/images/Predicting.png";
import SecurityAnalytics from "../../../assets/images/SecurityAnalytics.png";
import Smarkets from "../../../assets/images/Smarkets.png";

const OurPortfolio = ({ src }) => {
  const PortfolioArr = [
    {
      image: siftBg,
      description: `Sift is the perfect solution for small creative business owners. The app streamlines the process of custom order forms, client communication, and scheduling, all in one place. The goal is to free up more time for creating and less time on administrative tasks.`,
      title: "Sift - React Native Mobile App",
    },
    {
      image: Pals,
      description: `Introducing Pals: the modern way to connect with other dog owners in your community. The app offers a platform to easily and securely schedule playdates, find dog-friendly locations, and connect with other dog owners in your area.`,
      title: "Pals - React Native Mobile App",
    },
    {
      image: desktopIdea,
      description: `MapCraft Labs simplifies policy analysis by replacing complex spreadsheets and GIS maps with a cutting-edge, multi-user web app. Built using React and Typescript.`,
      title: "Mapcraft",
    },
    {
      image: corpWebsite,
      description: `Foretrace helps you understand your environment from the perspective of an attacker. Our platform's core modules reveal external vulnerabilities and utilize the same tools and techniques used by cybercriminals.`,
      title: "Foretrace",
    },
    {
      image: WeTravel,
      description: `WeTravel is a digital payment solution designed for small travel companies. Our platform enables businesses to transition away from paper-based and traditional finance, communication, and marketing methods, to a more modern and efficient approach, tailored to meet the needs of today's travel industry.`,
      title: "WeTravel",
    },

    {
      image: FestPop,
      description: `Discover new festivals with ease using FestPop. With over 5,000 festivals searchable in one place, you'll never run out of options. FestPop is built using React and Anti-design, which is a philosophy that focuses on creating simple, easy-to-use interfaces, and eliminate unnecessary elements, making it easy for you to find the perfect festival for you.`,
      title: "FestPop",
    },
    {
      image: Predicting,
      description: `Zirra is a leading business intelligence company that utilizes Artificial Intelligence to generate cutting-edge alternative data products. Our tech-driven approach allows us to provide valuable insights for businesses and investors.`,
      title: "Zirra",
    },
    {
      image: SecurityAnalytics,
      description: `Enigma Glass simplifies the process of understanding security data. Our solution includes data ingestion, analytics, insights, alerting, and reporting, making it easy for you to stay informed and keep your data secure.`,
      title: "Enigma Glass",
    },
    {
      image: Smarkets,
      description: `Smarkets betting exchange offers the most competitive odds and lowest commission rates on all major sports and political events. Whether you want to place a back or lay bet, our platform makes it easy for you to get the best value for your wagers.`,
      title: "Smarkets",
    },
  ];

  return (
    <>
      <div className="mt-24">
        <h1 className="text-[18px] space-portfolio text-textGray text-left md:text-center md:text-[20px]">
          OUR PORTFOLIOS
        </h1>
        <h1 className="text-[26px] text-left md:text-center md:text-[44px]">
          Explore <span className="text-voilet font-b ">Recent Projects </span>
        </h1>
      </div>
      <Swiper
        slidesPerView={1}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        modules={[EffectCoverflow, Autoplay]}
        // slidesPerView={"auto"}
        spaceBetween={10}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false,
          scale: 0.75,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 24,
          },

          1024: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
        }}
        initialSlide={1}
        // modules={[Pagination]}
        className="mySwiper my-8 md:mb-16"
      >
        {PortfolioArr.map((portFolio, index) => {
          return (
            <SwiperSlide key={`slide_${index}`}>
              <div className="text-center mt-2 flex flex-col ">
                <div className="bg-cardBg flex justify-center items-center rounded-xl">
                  <img
                    alt="mobile"
                    src={portFolio.image}
                    className="h-[300px] md:h-[400px] lg:h-[500px]  object-contain "
                  />
                </div>
                <div className="mt-4">
                  <p className="text-[16px]">DEVELOPMENT/IDEAS</p>
                  <p className="font-semibold  text-[24px]">
                    {portFolio.title}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export { OurPortfolio };
