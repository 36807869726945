import { Banner } from "../Components/Banner";
import { AboutUs } from "./AboutUs";
import { OurPortfolio } from "./OurPortfolios";
import { OurServices } from "./OurServices";
import { ClientTestimony } from "./ClientTestimony";

function Home() {
  return (
    <>
      <Banner />
      <AboutUs />
      <OurPortfolio />
      <OurServices />
      <ClientTestimony />
    </>
  );
}

export default Home;
