import styled from "@emotion/styled";
import { TitleBox } from "../Header";
import bannerIllustrationImage from "../../../assets/branding/banner-illustration-image.svg";
import waveMain from "../../../assets/images/wave-main-image.svg";
import mobileWaveMain from "../../../assets/images/mobile-wave-image.svg";

const breakPoint = 1200;

const BannerContainer = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    display: grid;
    grid-template-columns: 1fr repeat(12, 67px) 1fr;
    grid-template-areas: "Banner Banner Banner Banner Banner Banner Banner Banner Banner Banner Banner Banner Banner Banner";
    grid-gap: 0 30px;
    grid-template-rows: 900px;
    grid-area: Banner;
    width: 100%;
  }
`;

const BannerBackground = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    background-image: url(${(props) => props.src});
    background-size: cover;
    width: 100%;
    grid-area: Banner;

    display: grid;
    grid-template-columns: 1fr repeat(12, 67px) 1fr;
    grid-template-areas: "LeftMargin inBanner inBanner inBanner inBanner inBanner inBanner inBanner inBanner inBanner inBanner inBanner inBanner RightMargin";
    grid-gap: 0 30px;
    grid-template-rows: 832px;
    width: 100%;
  }
  @media screen and (max-width: ${breakPoint}px) {
    background-image: url(${mobileWaveMain});
    background-size: cover;
    background-position-x: right;
    width: 100%;
    height: 823px;
  }
`;
const BannerIllustrationContainer = styled.div`
  @media not screen and (max-width: ${breakPoint}px) {
    grid-area: inBanner;
    align-item: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: ${breakPoint}px) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
  }
`;

const BannerIllustration = styled.img`
  @media not screen and (max-width: ${breakPoint}px) {
    width: 550px;
    height: 500.89px;
    //margin-left: 108px;
  }

  @media screen and (max-width: ${breakPoint}px) {
    width: 375px;
    height: 554.89px;
  }
`;

const Banner = () => {
  return (
    <BannerContainer>
      <BannerBackground src={waveMain}>
        <BannerIllustrationContainer>
          <TitleBox
            title="Intl.dev Develops"
            subHeader="Next Era Applications"
            body="We empower companies across various industries with robust and flexible digital solutions tailored to meet the current demands."
          />
          <BannerIllustration src={bannerIllustrationImage} />
        </BannerIllustrationContainer>
      </BannerBackground>
      {/* <BannerBackground src={hero}/> */}
    </BannerContainer>
  );
};

export { Banner };
